<template>
  <div class="exhibitorFilter">
    <!-- <div class="formFilter">
      <p>地区</p>
      <div class="iconForm">
        <van-field readonly
                   clickable
                   name="area"
                   :value="model.region"
                   placeholder="请选择地区"
                   @click="start.region = true" />
        <van-icon name="play"
                  class="playIcons" />
        <van-popup v-model="start.region"
                   position="bottom">
          <van-area :area-list="areaList"
                    :columns-num="2"
                    cancel-button-text="清除"
                    @confirm="onConfirmRegion"
                    @cancel="cancelChange" />
        </van-popup>
      </div>
    </div> -->
    <div class="contianer">
      <div class="formFilter">
        <p>展馆</p>
        <ul class="overDer">
          <li :class="{'active':numActive1.indexOf(key)>-1}"
              v-for="(item,key) in exhibitionList"
              :key="item.id"
              @click="activeChange(key,1,item.id)">
            {{item.name}}
          </li>
        </ul>
      </div>
      <div class="formFilter">
        <p>展区</p>
        <ul class="overDer">
          <li :class="{'active':numActive2.indexOf(key)>-1}"
              v-for="(item,key) in exhibition_areaList"
              :key="item.id"
              @click="activeChange(key,2,item.id)">
            {{item.area_name}}
          </li>
        </ul>
      </div>
      <div class="formFilter bottomFilter">
        <p>展品</p>
        <ul class="overDer">
          <li :class="{'active':numActive3.indexOf(key)>-1}"
              v-for="(item,key) in pexhibitor_cateList"
              :key="item.id"
              @click="activeChange(key,3,item.id)">
            {{item.title}}
          </li>
        </ul>
      </div>
    </div>
    <footer class="bottomSubmit">
      <van-button round
                  block
                  type="info"
                  native-type="submit"
                  class="btnSubmit"
                  color="#46A05C"
                  @click="clearList">重置</van-button>
      <van-button round
                  block
                  type="info"
                  native-type="submit"
                  class="btnSubmit"
                  color="#46A05C"
                  @click="querySubmit">确定</van-button>
    </footer>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import {
  exhibitorCate,
} from "@/api/index";
export default {
  data () {
    return {
      model: {
        region: ''
      },
      start: {
        region: false,
      },
      areaList,
      exhibitionList: [],
      exhibition_areaList: [],
      pexhibitor_cateList: [],
      active: '',
      current_index1: -1,
      current_index2: -1,
      current_index3: -1,
      numActive1: [],
      numActive2: [],
      numActive3: [],
      province: '',
      city: '',
      exhibition_tube_id_ids: '',
      exhibition_area_id_ids: '',
      exhibitor_cate_id_ids: '',

      emitDate: [],
    }
  },
  created () {
    this.InfoTableList()
  },
  methods: {
    onConfirmRegion (val) {
      const datas = `${val[0].name} / ${val[1].name}`;
      this.province = val[0].name;
      this.city = val[1].name;
      this.model.region = datas;
      this.start.region = false;
    },
    cancelChange () {
      this.model.region = '';
      this.start.region = false;
      this.province = '';
      this.city = '';
    },
    querySubmit () {
      let datas = {
        limit: 10,
        page: 1,
        province: this.province,
        city: this.city,
        exhibition_tube_id: this.exhibition_tube_id_ids ? this.exhibition_tube_id_ids[0].id : '',
        exhibition_area_id: this.exhibition_area_id_ids ? this.exhibition_area_id_ids[0].id : '',
        exhibitor_cate_id: this.exhibitor_cate_id_ids ? this.exhibitor_cate_id_ids[0].id : '',
      }
      this.$emit('changeShow', datas, false);
    },
    clearList () {
      this.numActive1 = [];
      this.numActive2 = [];
      this.numActive3 = [];
      this.model.region = '';
      this.province = '';
      this.city = '';
      this.exhibition_tube_id_ids = '';
      this.exhibition_area_id_ids = '';
      this.exhibitor_cate_id_ids = '';
    },
    activeChange (key, e, ids) {
      if (e === 1) {
        this.exhibition_tube_id_ids = this.exhibitionList.filter(item => {
          return item.id === ids
        })
        let arrIndex = this.numActive1.indexOf(key);
        if (arrIndex > -1) {   // 已选中,点击取消
          this.numActive1.splice(arrIndex, 1);
          this.exhibition_tube_id_ids = ''
        } else {  // 未选中,点击选中
          if (this.numActive1.includes(key)) {
            this.numActive1.splice(arrIndex, key);
          } else {
            this.numActive1.splice(arrIndex, 1);
            this.numActive1.push(key);
          }
        }
      } else if (e === 2) {
        this.exhibition_area_id_ids = this.exhibition_areaList.filter(item => {
          return item.id === ids
        })
        let arrIndex = this.numActive2.indexOf(key);
        if (arrIndex > -1) {   // 已选中,点击取消
          this.numActive2.splice(arrIndex, 1);
          this.exhibition_area_id_ids = ''
        } else {  // 未选中,点击选中
          if (this.numActive2.includes(key)) {
            this.numActive2.splice(arrIndex, key);
          } else {
            this.numActive2.splice(arrIndex, 1);
            this.numActive2.push(key);
          }
        }
      }
      else if (e === 3) {
        this.exhibitor_cate_id_ids = this.pexhibitor_cateList.filter(item => {
          return item.id === ids
        })
        let arrIndex = this.numActive3.indexOf(key);
        if (arrIndex > -1) {   // 已选中,点击取消
          this.numActive3.splice(arrIndex, 1);
          this.exhibitor_cate_id_ids = ''
        } else {  // 未选中,点击选中
          if (this.numActive3.includes(key)) {
            this.numActive3.splice(arrIndex, key);
          } else {
            this.numActive3.splice(arrIndex, 1);
            this.numActive3.push(key);
          }
        }
      }
    },
    InfoTableList () {
      exhibitorCate().then(res => {
        this.exhibitionList = res.data.exhibition_tube;
        this.exhibition_areaList = res.data.exhibition_area;
        this.pexhibitor_cateList = res.data.exhibitor_cate;
      })
    },
  }
}
</script>

<style scoped lang="less">
.bottomFilter {
  padding-bottom: 100px !important;
}
.contianer {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
}
.bottomSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 9;
  .btnSubmit {
    flex: 1;
    margin: 15px;
    border-radius: 8px;
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
  }
}
.active {
  background: #46a05c !important;
  color: #fff;
}
.exhibitorFilter {
  background: #fff;
  height: 100%;
  padding-top: 10px;
}
.iconForm {
  position: relative;
  width: 150px;
  ::v-deep {
    .van-field__control {
      padding-right: 20px;
    }
    .van-cell {
      padding: 0;
      height: 32px;
      line-height: 32px;
      font-size: 13px;
    }
    .van-field__control {
      text-indent: 10px;
      background: #f5f5f5;
    }
  }
  .playIcons {
    position: absolute;
    top: 9px;
    right: 8px;
    transform: rotate(90deg);
    color: #999;
  }
}
.formFilter {
  font-size: 14px;
  text-align: left;
  padding: 4px 0 0 15px;
}
.overDer {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
  li {
    width: calc(calc(100% / 2) - 20px);
    height: 32px;
    margin: 6px;
    margin-top: 7px;
    text-align: center;
    line-height: 32px;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 12px;
  }
}
</style>
